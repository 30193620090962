<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :light="true"
    color="rgba(228, 226, 226, 1)"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <span class="logo-mini">
            <v-img :src="require(`@/assets/logo-mini.png`)" />
          </span>
          <div style="margin-top: 4px">
            <span
              class="logo-normal"
              style="text-transform: none"
            >EvalCard</span>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <base-item-group :item="profile" />
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
    <v-divider class="mb-2" />
    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedAdminItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
    <div style="height: 100px" />
    <div style="position: absolute; bottom: 0; width: 100%; text-align: center; background: rgb(228, 226, 226)">
      <div class="px-4 py-2" style="overflow: hidden; text-overflow: ellipsis;">
        <a href="https://evalus.at/datenschutz/" target="_blank">{{ $t('privacy-policy') }}</a>
      </div>
      <div style="opacity: 0.4">
        v{{ appVersion }}
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
  import { mapGetters } from 'vuex'
  import certificationService from '@/shared/services/certificationService'
  import { screenSizeIsMobile } from '@/shared/helpers'
  import { CompanyRole, IUserSubsegment } from '@/shared/model/company.model'
  import { CertificationAuthorityRole } from '@/shared/model/certificationAuthority.model'
  import companyService from '@/shared/services/companyService'
  import userService from '@/shared/services/userService'
  import axios from 'axios'
  import { version } from '../../../../package.json'

  export default {
    name: 'DashboardCoreDrawer',

    data: () => ({
      appVersion: version,
      adminItems: [
      ],
      items: [
        {
          group: '',
          icon: 'mdi-view-dashboard',
          title: 'dashboard',
          to: '/app',
          exactPath: true,
        },
        {
          group: '/app',
          icon: 'mdi-car',
          title: 'vehicles',
          children: [
            {
              title: 'my-vehicles',
              to: 'company-vehicles',
            },
          ],
        },
        {
          group: '/app',
          icon: 'mdi-clipboard-outline',
          title: 'protocols',
          to: '/app/reports',
        },
        {
          group: '/app',
          icon: 'mdi-cogs',
          title: 'my-company',
          children: [
            {
              title: 'settings',
              to: 'company-profile',
            },
          ],
        },
      ],
      image: '',
    }),

    computed: {
      drawer: {
        get () {
          return this.$store.getters.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      computedAdminItems () {
        return this.adminItems.map(this.mapItem)
      },
      profile () {
        return {
          avatar: this.image,
          group: '/app',
          title: this.account() ? this.account().username : 'anonymous',
          children: [
            {
              to: 'user',
              title: this.$t('my-profile'),
            },
            {
              to: 'change-password',
              title: this.$t('change-password'),
            },
          ],
        }
      },
      isMobile () {
        return screenSizeIsMobile()
      },
    },

    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      },
      '$store.getters.account': function () {
        this.setImage()
      },
      '$store.getters.company': function () {
        this.addCoworkerMenuIfNotExists()
      },
    },
    async mounted () {
      const account = this.$store.getters.account
      this.setImage()
      this.userHasValidCertificate()
      if (account.roles.includes('admin')) {
        this.adminItems.push({
          group: '/app',
          icon: 'mdi-anvil',
          title: 'system',
          children: [
            {
              title: 'user',
              to: 'users',
            },
            {
              title: 'companies',
              to: 'companies',
            },
            {
              title: 'vehicles',
              to: 'vehicles',
            },
            {
              title: 'certification-authorities',
              to: 'certification-authorities',
            },
          ],
        })
        this.adminItems.push(
          {
            group: '/app',
            icon: 'mdi-clipboard-outline',
            title: 'all-reports',
            to: '/app/all-reports',
          },
        )
      }
      const canCreateReports = await userService.canCreateReports()
      if(canCreateReports){
        this.adminItems.push({
          group: '/app',
          icon: 'mdi-alert-outline',
          title: 'info-signs',
          to: '/app/signs',
        })
      }
      if(canCreateReports || userService.userIsSystemAdmin()){
        this.adminItems.push({
          group: '/app',
          icon: 'mdi-forum',
          title: 'forum',
          to: '/app/forum',
        })
      }
      // TODO: Following check for the id is a quickfix which also is used in coworkerTable
      const id = this.$route.params.id
      if (!id) {
        companyService.getCompany(this.$store)
      }
      if (userService.userIsSystemAdmin()) {
        certificationService.getCertificationAuthorities(this.$store).then((result) => {
          this.pushMenuEntryIfCertAuthorityExists()
        })
      } else {
        certificationService.getMyCertificationAuthorities(this.$store).then((result) => {
          this.pushMenuEntryIfCertAuthorityExists()
        })
      }
    },
    methods: {
      ...mapGetters({
        account: 'account',
        certificationAuthorities: 'certificationAuthorities',
        company: 'company',
      }),
      certAuthorityUserIsReadOnly (authorities) {
        let isReadOnly = true

        for (const authority of authorities) {
          for (const user of authority.users) {
            if (user.user._id === this.account()._id) {
              if (
                user.authorityRoles.includes(CertificationAuthorityRole.authorityAdmin) ||
                user.authorityRoles.includes(CertificationAuthorityRole.trainer)
              ) {
                isReadOnly = false
              }
            }
          }
        }

        return isReadOnly
      },
      pushMenuEntryIfCertAuthorityExists () {
        if (this.certificationAuthorities()) {
          const children = [
            {
              title: 'settings',
              to: 'certification-authority',
            },
            {
              title: 'certificates',
              to: 'certificates',
            },
          ]

          if (!this.certAuthorityUserIsReadOnly(this.certificationAuthorities())) {
            children.push({
              title: 'issue-certificate',
              to: 'certificate/new',
            })
          }

          this.items.push({
            group: '/app',
            icon: 'mdi-certificate-outline',
            title: 'certifications',
            children,
          })
        }
      },
      addCoworkerMenuIfNotExists () {
        if (this.userIsAtLeastEvalClientOrSystemAdmin()) {
          if (this.items[3].children && this.items[3].children.length === 1) {
            this.items[3].children.unshift({
              title: 'coworker',
              to: 'coworker',
            })
          }
        }
      },
      userIsAtLeastEvalClientOrSystemAdmin () {
        if (this.company() && this.company().users) {
          const user = this.company().users.find((x) => {
            if (x.user && x.companyRoles && this.account()) {
              return x.user._id === this.account()._id
            }
          })
          if (user) {
            if (user.companyRoles.includes(CompanyRole.evalClient) || userService.userIsSystemAdmin()) {
              return true
            }
          }
        }
        return false
      },
      setImage () {
        if (this.account() && this.account().metadata.image && this.account().metadata.image.length < 30) {
          this.image = `/api/file/${this.account().metadata.image}`
        }
      },
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      async userHasValidCertificate () {
        try {
          if (this.account()) {
            const url = `/api/certificatesbyuser/${this.account()._id}`
            const result = await axios.get(url)
            if (
              (result && result.data && result.data.items && result.data.items.length) ||
              this.userIsAtLeastEvalClientOrSystemAdmin()
            ) {
              this.items[1].children.splice(0, 0, {
                title: 'search',
                to: 'vehicle',
              })
            }
          }
        } catch (err) {
          console.error(err)
        }
      },
    },
  }
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

    .v-list-group--sub-group
      display: block !important

  .v-list-group__header.v-list-item--active:before
    color: white
    opacity: .24
  .v-list-group__header.v-list-item--active
    color: black

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
